import { computed } from 'vue'
import { useLazyI18n } from '@/plugins/lazy-i18n'
import { useSearchFiltersStore } from '@/stores/searchFilters'
import translations from './Lang'
import { ServicesFiltersEnum } from '@/enums'
import { useGoogleAnalyticsHelpers } from '@/composables/googleAnalytics/GoogleAnalyticsHelpers'
import { GtmVariablesEnum } from '@/composables/googleAnalytics/GoogleTagManager.enum'

export function usePricingFilter(isMobile) {
  /**
   * Some initializations
   */
  const { $lt } = useLazyI18n(translations)
  const searchFiltersStore = useSearchFiltersStore()
  const { portalVisibilityFilterAnalytics } = useGoogleAnalyticsHelpers()

  /**
   * [Computed] Traductions
   */
  const lang = computed(() => ({
    withWithoutFees: $lt('withWithoutFees'),
    withoutFees: $lt('withoutFees'),
    label: $lt('label'),
    placeholder: $lt('placeholder')
  }))

  const filtersList = computed(() => [
    {
      title: lang.value.withWithoutFees,
      id: ServicesFiltersEnum.WITH_WITHOUT_FEES
    },
    {
      title: lang.value.withoutFees,
      id: ServicesFiltersEnum.WITHOUT_FEES
    }
  ])

  /**
   * [Computed] Current value
   */
  const currentValue = computed({
    get: () => {
      return getPricingFilter.value
    },
    set: (value) => {
      setPricingFilter(value)
      portalVisibilityFilterAnalytics(GtmVariablesEnum.PRICING, currentValue.value?.id ?? null)
    }
  })

  /**
   * [Function] Applies filter
   * @returns
   */
  const filter = (service: any): boolean => {
    let shouldShowService = true

    if (searchFiltersStore.withoutFeesFilter !== searchFiltersStore.withFeesFilter) {
      shouldShowService = service.pricingEnabled
        ? !searchFiltersStore.withoutFeesFilter
        : !searchFiltersStore.withFeesFilter
    }

    return shouldShowService
  }

  const getPricingFilter = computed(() => {
    const withFeesFilter = isMobile
      ? searchFiltersStore.mobile.withFeesFilter
      : searchFiltersStore.withFeesFilter

    const withoutFeesFilter = isMobile
      ? searchFiltersStore.mobile.withoutFeesFilter
      : searchFiltersStore.withoutFeesFilter

    if (withFeesFilter === null || withoutFeesFilter === null) {
      return null
    }

    return withFeesFilter && withoutFeesFilter ? filtersList.value[0] : filtersList.value[1]
  })

  const setPricingFilter = (value) => {
    const withAndWithoutFees = value?.id === ServicesFiltersEnum.WITH_WITHOUT_FEES
    const withoutFees = value?.id === ServicesFiltersEnum.WITHOUT_FEES

    if (isMobile) {
      searchFiltersStore.mobile.withoutFeesFilter = withoutFees || withAndWithoutFees
      searchFiltersStore.mobile.withFeesFilter = withAndWithoutFees
    } else {
      searchFiltersStore.withoutFeesFilter = withoutFees || withAndWithoutFees
      searchFiltersStore.withFeesFilter = withAndWithoutFees
    }
  }

  const applyMobileFilters = () => {
    searchFiltersStore.withoutFeesFilter = searchFiltersStore.mobile.withoutFeesFilter
    searchFiltersStore.withFeesFilter = searchFiltersStore.mobile.withFeesFilter
  }

  const clearMobileFilters = () => {
    searchFiltersStore.mobile.withoutFeesFilter = null
    searchFiltersStore.mobile.withFeesFilter = null
  }

  const clearWebFilters = () => {
    searchFiltersStore.withoutFeesFilter = null
    searchFiltersStore.withFeesFilter = null
  }

  return {
    lang,
    currentValue,
    filter,
    filtersList,
    setPricingFilter,
    applyMobileFilters,
    clearMobileFilters,
    clearWebFilters
  }
}
